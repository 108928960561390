'use client';

import type React from 'react';
import { useEffect } from 'react';

import { useDelayedEffect } from '@/components/useDelayedEffect';
import { useIsHydrated } from '@/components/useIsHydrated';

const hasLocalStorage = typeof globalThis.localStorage !== 'undefined';

export interface UseLocalFlag {
  (name: string): string | null;
  <T>(name: string, parse: (_: string) => T): T | null;
  <T, D = T>(name: string, parse: (_: string) => T, defaultValue: D): T | D;
}

const useNoOpLocalFlag = ((_name: string, _parse?: unknown, defaultValue: unknown = null) =>
  defaultValue) as UseLocalFlag;

// Read a flag from localStorage, if available.
export const useLocalFlag: UseLocalFlag = hasLocalStorage
  ? ((<T = string, D = null>(name: string, parse?: (_: string) => T, defaultValue = null as D): T | D => {
      // On the server, we can't read localStorage.
      // On the client, we can, but we need to wait until after hydration to avoid a mismatch.
      const isHydrated = useIsHydrated();
      let value;
      try {
        value = globalThis.localStorage.getItem(name);
      } catch (e) {
        console.error(e);
        value = null;
      }
      if (!isHydrated || value == null) {
        return defaultValue;
      }
      return parse != null ? parse(value) : (value as T);
    }) as UseLocalFlag)
  : useNoOpLocalFlag;

const isDevable = process.env.NODE_ENV !== 'production' && hasLocalStorage;

export type UseDevFlag = UseLocalFlag;

// Read a flag from localStorage, if available, and not in production.
export const useDevFlag = isDevable ? useLocalFlag : useNoOpLocalFlag;

/**
 * `useEffect`, possibly delayed by a flag in localStorage.
 */
export const useEffectWithDevDelay: (flagName: string, fn: React.EffectCallback, deps?: React.DependencyList) => void =
  isDevable
    ? (flagName, fn, deps) => {
        useDelayedEffect(
          useDevFlag(flagName, n => parseInt(n, 10)),
          fn,
          deps
        );
      }
    : // eslint-disable-next-line react-hooks/exhaustive-deps
      (_, fn, deps) => void useEffect(fn, deps);
