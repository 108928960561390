// https://github.com/vercel/next.js/blob/v14.2.13/packages/next-polyfill-nomodule/src/index.js

import 'core-js/features/string/dedent';

declare global {
  export interface StringConstructor {
    /**
     * `String.dedent` un-indents strings.
     *
     * @see {@link https://github.com/tc39/proposal-string-dedent}
     *
     * @param template A well-formed template string call site representation.
     * @param substitutions A set of substitution values.
     */
    dedent(template: TemplateStringsArray, ...substitutions: unknown[]): string;
  }
}

// We need some more polyfills for the "Iterable" constructor:
import 'core-js/proposals/iterator-helpers-stage-3-2'; // Builtin iterator class

// aggregate-error is implied by 'core-js/features/promise' which is part of next's default polyfill.
// import 'core-js/stable/aggregate-error';

import 'core-js/stable/promise/with-resolvers';

import 'core-js/stable/queue-microtask';

// Firefox currently does not support `Error.captureStackTrace`.
// There appears to be a feature flag, but in my testing (Firefox 136.0.1), it did not actually do anything yet.
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Error/captureStackTrace
// https://github.com/mdn/content/issues/37930
if (typeof window !== 'undefined' && !('captureStackTrace' in Error)) {
  Object.defineProperty(Error, 'captureStackTrace', {
    value: (_targetObject: object, _constructorOpt?: unknown): void => {},
    writable: true,
    configurable: true,
  });
}
